import * as React from "react"
import { FC } from "react"
import { Box } from "theme-ui"

import { Badge } from "components/Badge"

export interface IPropsTagList {
  tags: string[]
}

export const TagList: FC<IPropsTagList> = ({ tags }) => (
  <Box as="ul" sx={{ display: "inline-flex", gap: 1 }}>
    {tags.map((tag) => (
      <li key={tag}>
        <Badge>{tag}</Badge>
      </li>
    ))}
  </Box>
)
