import * as React from "react"
import { FC } from "react"
import { Badge as ThemeUiBadge } from "theme-ui"

export interface IPropsBadge {
  children: string
  className?: string
  variant?: "primary" | "primaryDark"
}

export const Badge: FC<IPropsBadge> = ({
  children,
  className,
  variant = "primary",
}) => (
  <ThemeUiBadge className={className} variant={variant}>
    {children}
  </ThemeUiBadge>
)
