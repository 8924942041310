/** @jsx jsx */
import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"
import { jsx, Box, Grid } from "theme-ui"

import { ButtonNext } from "./ButtonNext"
import { ButtonPrev } from "./ButtonPrev"
import { Numbering } from "./Numbering"

export interface IPropsPagination {
  currentPage: number
  numPages: number
}

export const Pagination: FC<IPropsPagination> = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/" : `/${(currentPage - 1).toString()}`
  const nextPage = `/${(currentPage + 1).toString()}`

  return (
    <Grid
      sx={{
        gridTemplateColumns: "min-content 1fr min-content",
        alignItems: "center",
        gridAutoFlow: "column",
      }}
    >
      <Box sx={{ width: [48, 56, null, 64, 80] }}>
        {!isFirst && (
          <Link
            to={prevPage}
            rel="prev"
            sx={{
              color: "text",
              display: "inline-grid",

              ":focus-visible": {
                outline: "1px dashed",
              },
            }}
          >
            <ButtonPrev />
          </Link>
        )}
      </Box>
      <Numbering numPages={numPages} />
      <Box sx={{ width: [48, 56, null, 64, 80] }}>
        {!isLast && (
          <Link
            to={nextPage}
            rel="next"
            sx={{
              color: "text",
              display: "inline-grid",

              ":focus-visible": {
                outline: "1px dashed",
              },
            }}
          >
            <ButtonNext />
          </Link>
        )}
      </Box>
    </Grid>
  )
}
