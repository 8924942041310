/** @jsx jsx */
import * as React from "react"
import { FC } from "react"
import { jsx } from "theme-ui"

import { IconArrowStickLeft } from "icons"
import { PaginationButton } from "../Button"

export const ButtonPrev: FC = () => {
  return (
    <PaginationButton ghost={true}>
      <IconArrowStickLeft
        sx={{
          width: "50%",
          height: "50%",
          transition: "transform 0.5s var(--animation-bezier)",
          transform: "translateX(25%)",
          transformOrigin: "right",

          "a:hover &": {
            transform: "translateX(0%)",
            transitionDuration: "0.125s",
          },

          "path:first-of-type": {
            transform: "scaleX(0)",
            transition: "transform 0.5s var(--animation-bezier)",
          },

          "a:hover & path:first-of-type": {
            transform: "scaleX(1)",
            transitionDuration: "0.125s",
          },
        }}
      />
    </PaginationButton>
  )
}
