/** @jsx jsx */
import { FC } from "react"
import { Link } from "gatsby"
import { jsx, Box, Divider, Grid, Heading, SxStyleProp, Text } from "theme-ui"

import { Orphans } from "components/Orphans"
import { TagList } from "components/TagList"

export interface IPropsFeaturedPost {
  author?: string
  className?: string
  date: string
  description?: string
  slug: string
  tags?: string[]
  title: string
  timeToRead?: number
}

const columnStyle: SxStyleProp = {
  gridColumn: ["3/13", "4/13", "4/12", "4/11", "4/10"],
}

export const FeaturedPost: FC<IPropsFeaturedPost> = ({
  author,
  date,
  tags,
  title,
  description,
  slug,
  timeToRead,
}) => {
  return (
    <Link
      to={slug}
      sx={{
        textDecoration: "none",
        color: "text",
        display: "block",

        ":focus-visible": {
          outline: "1px dashed",
        },
      }}
    >
      <Grid variant="base" sx={{ alignItems: "baseline", rowGap: 0 }}>
        <Divider
          sx={{
            mt: 0,
            mb: [5, 6, 7],
            height: "4px",
            border: 0,
            width: "100%",
            ["--thickness"]: "6px",
            position: "relative",
            bg: "backgroundTertiary",
            color: "text",
            backgroundImage:
              "linear-gradient(to bottom, currentColor 100%, transparent 0%)",
            backgroundSize: "0% var(--thickness)",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            backgroundPositionY: "bottom",
            transition: "background-size 0.5s var(--animation-bezier)",
            gridColumn: [null, null, null, "span 8"],
            "a:hover &": {
              backgroundSize: "100% var(--thickness)",
              backgroundPositionX: "left",
              backgroundPositionY: "bottom",
            },
            ...columnStyle,
          }}
        />
        <Heading
          as="h1"
          sx={{
            mb: [3, null, null, null, 4, 5],
            fontSize: [6, 8, 9, null, 10, 12],
            lineHeight: [1.4, 1.3],
            letterSpacing: "tight5",
            gridRow: "3/4",
            ...columnStyle,
          }}
        >
          <Orphans>{title}</Orphans>
        </Heading>
        {description && (
          <Text
            sx={{
              mb: [3, null, null, null, 4, 5],
              fontSize: [3, 4, null, null, null, 5],
              gridRow: "4/5",
              ...columnStyle,
            }}
          >
            <Orphans>{description}</Orphans>
          </Text>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gridRow: "5/6",
            gridColumn: ["3/13", "4/11", "4/10", "4/9"],
          }}
        >
          <Text
            sx={{
              fontSize: [1, 2, null, null, 3],
              color: "textMuted",
            }}
          >
            {new Date(date).toDateString()}
          </Text>
          <Text
            sx={{
              fontSize: [1, 2, null, null, 3],
              color: "textMutedMore",
            }}
          >
            <Text as="span" sx={{ color: "currentColor", mx: [2] }}>
              —
            </Text>
            by {author}
          </Text>
        </Box>
        <Text
          sx={{
            gridRow: ["2/3", "5/6"],
            gridColumn: ["3/13", "10/13", "10/12", "9/11", "8/10"],
            mb: [3, 0, null, 3],
            fontSize: [1, null, null, null, 2, 3],
            letterSpacing: "tight3",
            color: "textMutedMore",
            textAlign: ["left", "right"],
          }}
        >
          {timeToRead} min read
        </Text>
        {tags && (
          <Box
            sx={{
              mt: 5,
              gridRow: "6/7",
              ...columnStyle,
            }}
          >
            <TagList tags={tags} />
          </Box>
        )}
      </Grid>
    </Link>
  )
}
