import * as React from "react"
import { FC } from "react"

import { IPropsIcon } from "icons"

export const ArrowStickRight: FC<IPropsIcon> = ({ className }) => (
  <svg
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path
        d="m45.4123789 22.346c.9664983 0 1.75.7835017 1.75 1.75 0 .9181734-.7071103 1.6711923-1.6064728 1.7441988l-.1435272.0058012h-43.2043789c-.96649831 0-1.75-.7835017-1.75-1.75 0-.9181734.70711027-1.6711923 1.60647279-1.7441988l.14352721-.0058012z"
        transform="matrix(-1 0 0 1 47.620378 0)"
      />
      <path
        d="m44.1053002 8.43146532c.6453639-.71946122 1.7517733-.77952898 2.4712345-.13416509.6794911.60951033.7708196 1.63026184.2352018 2.34788677l-.1010367.1233477-11.9126998 13.2794653 11.9126998 13.2794653c.6095103.6794912.589793 1.7041305-.0203683 2.3595503l-.1137968.1116842c-.6794912.6095103-1.7041305.589793-2.3595503-.0203683l-.1116842-.1137968-12.96-14.448c-.5591253-.6233212-.5940706-1.5476165-.104836-2.2085594l.104836-.12851z"
        transform="matrix(-1 0 0 1 77.856016 0)"
      />
    </g>
  </svg>
)
