/** @jsx jsx */
import { FC } from "react"
import * as React from "react"
import { graphql } from "gatsby"
import { useSpring } from "react-spring"
import { jsx, Box, Container, Grid } from "theme-ui"

import { Footer } from "components/Footer"
import { GridOverlay } from "components/GridOverlay"
import { Layout } from "components/Layout"
import { LogoEngineeringAnimated } from "icons/LogoEngineeringAnimated"
import { Pagination } from "components/Pagination"
import { SEO } from "components/Seo"
import { Summary } from "components/Summary"
import { useSiteMetadata } from "hooks/use-site-metadata"

export interface IPropsIndex {
  data: any
  pageContext: any
}

const Index: FC<IPropsIndex> = ({ data, pageContext }) => {
  const { title } = useSiteMetadata()
  const posts = data.allMdx.edges
  const { numPages, currentPage } = pageContext
  const location = currentPage === 1 ? "/" : `/${currentPage}`

  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ]

  const onMove = React.useCallback(
    ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) }),
    []
  )

  return (
    <Layout navOnLoad="transparent">
      <SEO title={title} location={location} />
      <div onMouseMove={onMove}>
        <Box
          sx={{
            display: "grid",
          }}
        >
          <Box
            sx={{
              gridRow: 1,
              gridColumn: 1,
              pt: 160,
            }}
          >
            <Summary posts={posts} />
          </Box>
          <Container>
            <Grid variant="base">
              <Box
                sx={{
                  my: [13, 14, 13, null, 15],
                  gridColumn: ["3/13", "4/13", "4/12", "4/11", "4/10"],
                }}
              >
                <Pagination numPages={numPages} currentPage={currentPage} />
              </Box>
            </Grid>
          </Container>
        </Box>
      </div>
      <GridOverlay />
      <Footer />
    </Layout>
  )
}

export default Index

export const postsQuery = graphql`
  query Posts($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          rawBody
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            author
            date
            description
            tags
            title
          }
        }
      }
    }
  }
`
