import * as React from "react"
import { FC } from "react"

import { IPropsIcon } from "icons"

export const Quote: FC<IPropsIcon> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="31"
    fill="none"
    viewBox="0 0 48 31"
  >
    <path
      fill="currentColor"
      d="M47.89 18.89c-.694-4.64-4.641-7.948-9.122-8.161 1.12-4.16 3.84-5.814 7.148-6.775.32-.106.267-.426.267-.426L45.649.22s-.053-.266-.48-.213C33.754 1.287 26.02 9.982 27.46 20.544c1.334 7.308 7.041 10.135 12.322 9.388 5.28-.853 8.908-5.76 8.107-11.042zM11.457 10.73c1.067-4.16 3.894-5.814 7.148-6.775.32-.106.266-.426.266-.426L18.392.22s-.053-.267-.48-.214C6.498 1.234-1.183 9.93.15 20.49c1.387 7.362 7.148 10.189 12.375 9.442 5.28-.853 8.908-5.76 8.054-11.042-.693-4.694-4.587-8.001-9.12-8.161z"
    ></path>
  </svg>
)
