import * as React from "react"
import { FC } from "react"

import { IPropsIcon } from "icons"

export const ArrowStickLeft: FC<IPropsIcon> = ({ className }) => (
  <svg
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path d="m45.504 22.56c.8483094 0 1.536.6876906 1.536 1.536 0 .8011811-.6134031 1.4590942-1.3961927 1.5297229l-.1398073.0062771h-43.20437887c-.84830938 0-1.536-.6876906-1.536-1.536 0-.8011811.61340305-1.4590942 1.39619269-1.5297229l.13980731-.0062771z" />
      <path d="m14.1206018 8.57436042c.5664451-.63148139 1.5375564-.68420372 2.1690378-.11775862.5920138.53104228.6753526 1.41774821.2167211 2.0468367l-.0989625.1222011-12.0403982 13.4223604 12.0403982 13.4223604c.5310423.5920138.5178944 1.4825305-.0061481 2.0582689l-.1116105.1107689c-.5920138.5310423-1.4825305.5178944-2.0582689-.0061481l-.1107689-.1116105-12.96-14.448c-.48857113-.5446663-.52114254-1.351159-.09771423-1.9307355l.09771423-.1205437z" />
    </g>
  </svg>
)
