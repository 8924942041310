import * as React from "react"
import { FC } from "react"
import { Box, Container } from "theme-ui"

import { FeaturedPost } from "components/FeaturedPost"

interface Post {
  fields: { slug: string }
  frontmatter: {
    author: string
    date: string
    tags: string[]
    title: string
    description: string
  }
  timeToRead: number
}

export interface IPropsSummary {
  posts: { node: Post }[]
}

export const Summary: FC<IPropsSummary> = ({ posts }) => {
  return (
    <Container as="ul">
      {posts.map(({ node }) => {
        const { slug } = node.fields
        const { author, date, tags, title, description } = node.frontmatter
        const { timeToRead } = node
        return (
          <Box
            as="li"
            sx={{
              mb: [11, 12, 11, null, 12, 13],
              ":last-child": {
                mb: 0,
              },
            }}
            key={slug}
          >
            <FeaturedPost
              author={author}
              date={date}
              description={description}
              slug={slug}
              tags={tags}
              timeToRead={timeToRead}
              title={title}
            />
          </Box>
        )
      })}
    </Container>
  )
}
