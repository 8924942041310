import * as React from "react"
import { FC } from "react"

import { IconButton, IconButtonProps } from "theme-ui"

export interface IPropsPaginationButton extends IconButtonProps {
  ghost: boolean
}

export const PaginationButton: FC<IPropsPaginationButton> = ({
  as,
  children,
  ghost,
}) => {
  return (
    <IconButton
      as={as}
      sx={{
        width: [48, 56, null, 64, 80],
        height: [48, 56, null, 64, 80],
        borderRadius: "50%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        bg: ghost ? "backgroundTertiary" : "transparent",
        color: "text",
        cursor: "pointer",
        outline: "none",
        ":hover": {
          bg: "backgroundPrimary",
        },
        ":active": {
          filter: "brightness(0.95)",
        },
        'a[aria-current="page"] & ': {
          border: "2px solid",
          borderColor: "backgroundTertiary",
          color: "textMutedMore",
          pointerEvents: "none",
          cursor: "auto",
        },
      }}
    >
      {children}
    </IconButton>
  )
}
