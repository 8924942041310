/** @jsx jsx */
import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"
import { jsx, Box, Text } from "theme-ui"
import { PaginationButton } from "../Button"

export interface IPropsNumbering {
  numPages: number
}

export const Numbering: FC<IPropsNumbering> = ({ numPages }) => (
  <Box
    as="ul"
    sx={{
      display: "grid",
      gap: [2, 3, 4],
      gridAutoColumns: "min-content",
      alignItems: "center",
      justifyContent: "center",
      gridAutoFlow: "column",
      textAlign: "center",
    }}
  >
    {Array.from({ length: numPages }, (_, i) => (
      <li key={`pagination-number${i + 1}`}>
        <Link
          to={`/${i === 0 ? "" : i + 1}`}
          sx={{
            display: "inline-grid",
            textDecoration: "none",
            color: "text",

            ":focus-visible": {
              outline: "1px dashed",
            },
          }}
        >
          <PaginationButton as="div" ghost={false}>
            <Text
              sx={{
                color: "currentColor",
                fontSize: [5, 7],
              }}
            >
              {i + 1}
            </Text>
          </PaginationButton>
        </Link>
      </li>
    ))}
  </Box>
)
